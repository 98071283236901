import { useState } from "react";

export default function Form({isContactUs = false}) {
  const [errors, setErrors] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);

  async function submitContactForm(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const response = await fetch("/forms/contact-us/", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    if (data.status === "errors") {
      setErrors(data.errors);
    } else {
      setFormSuccess(true);
      $('.contact-us-form input, .contact-us-form textarea').val('');
    }
  }

  const toggleShowForm = () => {
    console.log('test');
    setShowForm(!showForm);
    setFormSuccess(false);
  };

  return (
    <>
      <span className="text-link" onClick={toggleShowForm}>{isContactUs ? 'Contact us' : 'Ask a question ›'}</span>
      {showForm && (
        <div className="contact-us-form">
          <button className="contact-us-form__close" onClick={toggleShowForm}>&times;</button>
          <div className="contact-us-form__inner">
            {formSuccess ? (
              <div className="contact-us-form__success">
                <div style={{fontSize:'4rem'}}>📠</div>
                <div className="h4 mb-1 contact-us-form__heading">Message received.</div>
                <p className="contact-us-form__description mb-0">We will follow up with you within 24 hours.</p>
              </div>
            ) : (
              <>
                <div className="h4 mb-1 contact-us-form__heading">Ask a question</div>
                <p className="contact-us-form__description">We will get you an answer within 24 hours.</p>
                <form className="contact-us-form__form" onSubmit={submitContactForm}>
                  <div className="form-group form-group--50">
                    <label htmlFor="first_name">First name</label>
                    <input type="text" id="first_name" name="first_name" autocomplete="given-name" required />
                    {errors.first_name && <p className="form-error">{errors.first_name}</p>}
                  </div>
                  <div className="form-group form-group--50">
                    <label htmlFor="last_name">Last name</label>
                    <input type="text" id="last_name" name="last_name" autocomplete="family-name" required />
                    {errors.first_name && <p className="form-error">{errors.last_name}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" autocomplete="email" required />
                    {errors.email && <p className="form-error">{errors.email}</p>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">{isContactUs ? 'Your message' : 'What is your question?'}</label>
                    <textarea type="text" id="message" name="message" autocomplete="off" required></textarea>
                    {errors.message && <p className="form-error">{errors.message}</p>}
                  </div>
                  <input type="submit" className="button" value="Send Message" />
                </form>
              </>
            )}
            
          </div>
        </div>
      )}
    </>
  );
}